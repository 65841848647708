import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import { H1Citrina } from 'src/components/Text/Headings'
import { graphite } from 'src/styles/newColors'
import { InterP } from 'src/components/Text/BodyText'
import { ButtonWithDeepLink } from 'src/pages/recarga/components/_ButtonWithDeepLink'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { ImageWebpWithSizes } from 'src/components/ImageWebp'
import SectionComponent from 'src/components/SectionComponent'
import { HeroPixProps } from '../_types'
import { wrappTextOnStrongTag } from '../utils/_wrappTextOnStrongTag'

const TEXT_CONTENT = {
  heading: 'Pix Inter: Nota A no Índice de Qualidade e Serviço',
  paragraph1: '34 meses Nota A no IQS do Banco Central. Faça transações rápidas e seguras de forma gratuita!',
  cta: 'Cadastrar chave agora',
}

const dataLayerEvent: IDataLayerParams = {
  section: 'dobra_01',
  element_action: 'click button',
  element_name: TEXT_CONTENT.cta,
  section_name: TEXT_CONTENT.heading,
}

const HeroPix = ({ onCtaClickHandler, oneLink }: HeroPixProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const handleBtnClick = () => {
    onCtaClickHandler(HeroPix.name)
    sendDatalayerEvent(dataLayerEvent)
  }

  return (
    <SectionComponent
      id='hero-pix'
      minHeight={{ sm: '250px', md: '374', lg: '500px', xl: '703px', xxl: '703px' }}
    >
      <div className='col-12 col-md-5 col-lg-6'>
        <H1Citrina
          color={graphite}
          fontSize={{ md: '25px', xl: '48px' }}
          lineHeight={{ md: '30px', xl: '57px' }}
          fontWeight={{ sm: '700', md: '700', lg: '700', xl: '700' }}
          className='mb-3 '
        >
          {TEXT_CONTENT.heading}
        </H1Citrina>
        <InterP className='mb-sm-3' fontSize={{ md: '13px' }} lineHeight={{ md: '20px' }}>
          { TEXT_CONTENT.paragraph1 }
        </InterP>
        <div>
          <ButtonWithDeepLink
            className='mt-3 btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none text-md-center'
            href={oneLink}
            onClick={handleBtnClick}
            onLinkClick={() => sendDatalayerEvent(dataLayerEvent)}
          >
            {TEXT_CONTENT.cta}
          </ButtonWithDeepLink>
        </div>
      </div>
      {width >= WIDTH_MD &&
        <div className='col-12 col-md-6 col-lg-6 order-md-2 mb-4 mb-md-0'>
          <ImageWebpWithSizes
            className='float-lg-right'
            widths={{ md: 306, lg: 376, xl: 529 }}
            pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/pix-dobra1/image.webp'
            altDescription='homem e mulher dentro de veículo olhando para celular que está na mão do homem'
          />
        </div>
      }
    </SectionComponent>
  )
}

export default HeroPix
