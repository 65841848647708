import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { orange } from 'src/styles/colors'

import { H2Citrina, H3Citrina } from 'src/components/Text/Headings'
import { InterP } from 'src/components/Text/BodyText'
import { ButtonWithDeepLink } from 'src/pages/recarga/components/_ButtonWithDeepLink'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { sendDataLayerEvent } from 'src/shared/helpers'
import { primary } from 'src/styles/newColors'
import SectionComponent from 'src/components/SectionComponent'
import * as S from './_style'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_LG } from 'src/utils/breakpoints'
import { MelhorPixProps } from '../_types'

const CARDS_CONTENT = [
  {
    icon: 'check-circle',
    title: 'Nota A',
    body: '34 meses Nota A no Índice de Qualidade e Serviço do Banco Central.',
  },
  {
    icon: 'no-taxes',
    title: 'Pix transacional gratuito',
    body: 'Com o Pix Inter, você envia e recebe dinheiro de forma gratuita e instantânea.',
  },
  {
    icon: 'negociação-de-dívidas',
    title: 'Seguro',
    body: 'Suas transferências são mais seguras e protegidas pelas autenticações do iSafe.',
  },
  {
    icon: 'list-check',
    title: 'PF, PJ e MEI',
    body: 'Disponível para PF, PJ e MEI.',
  },
  {
    icon: 'alarm-on',
    title: 'Sempre disponível',
    body: 'Está sempre disponível, pra você acessar a qualquer hora do dia.',
  },
]

const TEXT_CONTENT = {
  heading: 'Por que o Inter tem o melhor Pix?',
  cta: 'Fazer Pix',
}

const dataLayerEvent: IDataLayerParams = {
  section: 'dobra_02',
  element_action: 'click button',
  element_name: TEXT_CONTENT.cta,
  section_name: TEXT_CONTENT.heading,
}

const MelhorPix = ({ oneLink, onCtaClickHandler }: MelhorPixProps) => {
  const width = useWidth()

  const handleBtnClick = () => {
    onCtaClickHandler(MelhorPix.name)
    sendDataLayerEvent(dataLayerEvent)
  }
  return (
    <SectionComponent
      id='melhor-pix'
      bgColor={primary[100]}
      minHeight={{ sm: '1066px', md: '496px', lg: '521px', xl: '734px', xxl: '734px' }}
    >
      <div className='col-12 text-md-center'>
        <H2Citrina
          fontWeight={{ sm: '700', md: '700', lg: '700', xl: '700' }}
          className='mb-4'
          fontSize={{ xl: '40px' }}
        >{TEXT_CONTENT.heading}
        </H2Citrina>
      </div>
      <div className='col-12'>
        <S.CardFlexbox>
          {CARDS_CONTENT.map((item: {icon: string; title: string; body: string}, key: number) => (
            <S.CardWithHeight useShadow={false} key={'dobra_02' + key}>
              <div className='d-flex align-items-center w-100 mb-2'>
                <OrangeIcon className='mr-2' icon={item.icon} size={width >= WIDTH_LG ? 'MD' : 'SM'} color={orange.extra} />
                <H3Citrina
                  fontSize={{ md: '15px', lg: '15px', xl: '20px' }}
                  fontWeight={{ sm: '500', md: '500', lg: '500', xl: '500' }}
                >{item.title}
                </H3Citrina>
              </div>
              <InterP fontSize={{ md: '13px', lg: '13px', xl: '15px' }}>{item.body}</InterP>
            </S.CardWithHeight>
          ))}
        </S.CardFlexbox>
      </div>
      <div className='col-12'>
        <ButtonWithDeepLink
          className='d-block mx-auto mt-4 btn btn-orange--extra btn--lg rounded-2 fs-14 fw-700 text-none text-md-center'
          href={oneLink}
          onClick={handleBtnClick}
          onLinkClick={() => sendDataLayerEvent(dataLayerEvent)}
        >
          {TEXT_CONTENT.cta}
        </ButtonWithDeepLink>
      </div>
    </SectionComponent>
  )
}

export default MelhorPix
